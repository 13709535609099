import * as yup from 'yup';

const ssuModalValidator = yup.object().shape({
  siteNumber: yup.string().required('You must enter a Site Number'),
  status: yup.string().required('You must select a valid status'),
  studyId: yup.string().required('You must select a Study'),
  siteId: yup.string().required('You must select a Site')
});

export default ssuModalValidator;
