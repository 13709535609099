/*
  Selector for user page `/users/:uuid`
*/
import { createSelector } from 'reselect';
import { ssuRoles, globalRoles, timeZones, timeZonesMap } from '../constants';
import {
  getSsuList,
  getAllSites,
  getAllStudies,
  getAllPcns,
  getPcnStudyMap,
  getSiteStudyMap
} from './dataSourcesService';
import { generateMomentDate, generateDateStr } from './utils';
import bouncerToken from '../fetchers/bouncerToken';

const usersSelector = state => state.users || {};

const cognitoUsersSelector = state => state.cognitoUsers || {};

const ssusSelector = state => state.ssus || {};

const studiesSelector = state => state.studies || {};

const sitesSelector = state => state.sites || {};

const roleTxnsRolledUpSelector = state => state.roleTxnsRolledUp || {};

const roleTxnsSelector = state => state.roleTxns || {};

const previousRolesSelector = state => state.previousRoles || {};

const findTimeZoneObj = (googleZoneName, abbr) => {
  let timeZone = (timeZones && timeZones.length && timeZones[0]) || null;
  if (googleZoneName) {
    const tzOptions = timeZonesMap[googleZoneName];
    if (tzOptions && tzOptions.length) {
      timeZone =
        (abbr && tzOptions.find(tz => tz.abbr === abbr)) || tzOptions[0];
    }
  }
  return timeZone;
};

const userSelector = (
  users,
  cognitoUsers,
  ssus,
  studies,
  sites,
  roleTxnsRolledUp,
  previousRoles,
  roleTxns,
  uuid
) => {
  const ssuList = getSsuList(ssus);
  const pcnStudyMap = getPcnStudyMap(ssus, ssuList, studies);
  const siteStudyMap = getSiteStudyMap(ssuList, ssus);

  const { bouncerEnabled } = bouncerToken.getBouncerProps();
  // Get user info
  const user = users[uuid] || {};

  // Get cognito user info
  let cognitoUser = cognitoUsers[uuid] || {};
  if (bouncerEnabled && user && user.providerId) {
    // TODO: refactor namings because in this case it is Auth0 user but no longer Cognito
    cognitoUser = cognitoUsers[user.providerId] || {};
  }

  // Attempt to fix user startDates that are not formatted correctly for
  // date inputs which expect YYYY-MM-DD formats. Only fixes dates
  // that look like this: YYYY-MM-D, YYYY-M-D, YYYY-M-DD
  const startDateArr = user.startDate && user.startDate.split('-');
  const fixedStartDate =
    startDateArr &&
    startDateArr.length === 3 &&
    [
      startDateArr[0],
      ...startDateArr.slice(1, 3).map(i => (i.length === 1 && `0${i}`) || i)
      // if single digit for day or month prefix a '0'
    ].join('-');
  const userRoles = user.role || {};
  const userRoleTxnsRolledUp = roleTxnsRolledUp[uuid] || {};

  // Get user's Current Roles with Dates and SSU info
  const roles = Object.keys(userRoles).reduce((accumulator, roleName) => {
    const roleInfo = userRoleTxnsRolledUp[roleName] || {};
    // Get list of current ssu ids if ssu role
    const ssuIds = (ssuRoles.includes(roleName) && userRoles[roleName]) || [];

    if (globalRoles.includes(roleName)) {
      const momentStartDate = generateMomentDate(
        roleInfo.startDate,
        roleInfo.googleZoneName
      );
      const momentExpireDate = generateMomentDate(
        roleInfo.expireDate,
        roleInfo.googleZoneName
      );
      accumulator.push({
        app: 'Directsource',
        roleName,
        momentStartDate,
        momentExpireDate,
        startDate: generateDateStr(momentStartDate),
        expireDate: generateDateStr(momentExpireDate),
        timeZone: roleInfo.timeZone,
        googleZoneName: roleInfo.googleZoneName,
        timeZoneObj: findTimeZoneObj(roleInfo.googleZoneName, roleInfo.timeZone)
      });
    } else {
      accumulator.push(
        // push can take multiple items at once
        // map returns an array, but spread operator (...) will
        // expand it into seperate items
        ...ssuIds.map(ssuId => {
          const ssu = ssus[ssuId] || {};
          const ssuInfo = roleInfo[ssuId] || {};
          const momentStartDate = generateMomentDate(
            ssuInfo.startDate,
            ssuInfo.googleZoneName
          );
          const momentExpireDate = generateMomentDate(
            ssuInfo.expireDate,
            ssuInfo.googleZoneName
          );
          return {
            app: 'Directsource',
            roleName,
            studyName: ssu.studyName || '',
            siteName: ssu.siteName || '',
            momentStartDate,
            momentExpireDate,
            startDate: generateDateStr(momentStartDate),
            expireDate: generateDateStr(momentExpireDate),
            timeZone: ssuInfo.timeZone,
            googleZoneName: ssuInfo.googleZoneName,
            timeZoneObj: findTimeZoneObj(
              ssuInfo.googleZoneName,
              ssuInfo.timeZone
            ),
            ssuId,
            blindingGroup: ssuInfo.blindingGroup || null,
            groupAssign: ssu.isGroupAssign
              ? ssu.groupAssigns.find(
                  ({ _id }) => _id === ssuInfo.groupAssignId
                )
              : null,
            financeDetailsAccess: ssuInfo.financeDetailsAccess || false,
            expensesAccess: ssuInfo.expensesAccess || false,
            patientPaymentAccess: ssuInfo.patientPaymentAccess || false,
            remittanceNotification: ssuInfo.remittanceNotification || false
          };
        })
      );
    }
    return accumulator;
  }, []);

  const cognitoGroups = (cognitoUser && cognitoUser.groups) || [];

  const fullRoles = [...roles];

  // Get Expired Roles with Dates and SSU info
  const previousRolesFull =
    (uuid in previousRoles &&
      previousRoles[uuid].map(role => {
        const ssu = ssus[role.ssu] || {};
        const roleInfo = userRoleTxnsRolledUp[role.role] || {};

        // If global no need to dig deeper
        // else find for specific ssu
        const dateInfo =
          (globalRoles.includes(role.role) && roleInfo) ||
          (role.ssu in roleInfo && roleInfo[role.ssu]) ||
          {};
        const momentStartDate = generateMomentDate(
          dateInfo.startDate,
          dateInfo.googleZoneName
        );
        const momentExpireDate = generateMomentDate(
          dateInfo.expireDate,
          dateInfo.googleZoneName
        );
        return {
          app: 'Directsource',
          roleName: role.role,
          studyName: ssu.studyName || '',
          siteName: ssu.siteName || '',
          timeZone: dateInfo.timeZone || 'UTC',
          googleZoneName: dateInfo.googleZoneName || 'America/Chicago',
          timeZoneObj: findTimeZoneObj(
            dateInfo.googleZoneName,
            dateInfo.timeZone
          ),
          momentStartDate,
          momentExpireDate,
          startDate: generateDateStr(momentStartDate),
          expireDate: generateDateStr(momentExpireDate),
          ssuId: role.ssu,
          blindingGroup:
            role.ssu in roleInfo ? roleInfo[role.ssu].blindingGroup : null,
          groupAssign: ssu.isGroupAssign
            ? ssu.groupAssigns.find(({ _id }) => _id === dateInfo.groupAssignId)
            : null,
          financeDetailsAccess:
            role.ssu in roleInfo
              ? roleInfo[role.ssu].financeDetailsAccess
              : null,
          expensesAccess:
            role.ssu in roleInfo ? roleInfo[role.ssu].expensesAccess : null,
          patientPaymentAccess:
            role.ssu in roleInfo
              ? roleInfo[role.ssu].patientPaymentAccess
              : null,
          remittanceNotification:
            role.ssu in roleInfo
              ? roleInfo[role.ssu].remittanceNotification
              : null
        };
      })) ||
    [];

  // Get all user's Role Txns with SSU & Created by
  const roleTxnsInfo =
    (uuid in roleTxns &&
      roleTxns[uuid].map(role => {
        const ssu = ssus[role.ssu] || {};
        const createdByUser = users[role.createdBy] || {};
        const momentCreatedOn = generateMomentDate(role.createdOn);
        return {
          ...role,
          studyName: ssu.studyName || '',
          siteName: ssu.siteName || '',
          createdByEmail: createdByUser.email || '',
          app: 'Directsource',
          createdOn: momentCreatedOn
        };
      })) ||
    [];

  const allTxns = [...roleTxnsInfo].sort((a, b) => {
    if (a.createdOn < b.createdOn) {
      return -1;
    }
    return 1;
  });

  const allStudiesDataSource = getAllStudies(ssuList, ssus);
  const allSitesDataSource = getAllSites(ssuList, ssus);
  const allPcnsDataSource = getAllPcns(ssuList, ssus, studies);
  return {
    userInfo: {
      ...user,
      startDate: fixedStartDate || user.startDate,
      cognitoGroups: cognitoGroups.join(', ')
      // Get all info from `user` + `fixedStartDate` + `cognitoGroups`
    },
    userRoles: fullRoles,
    ssus,
    ssuList,
    allStudiesDataSource,
    allSitesDataSource,
    allPcnsDataSource,
    pcnStudyMap,
    siteStudyMap,
    studies,
    sites,
    previousRoles: [...previousRolesFull],
    roleTxns: allTxns || [],
    roleTxnsRolledUp: userRoleTxnsRolledUp,
    cognitoUser
  };
};

const makeUserSelector = () => {
  return createSelector(
    usersSelector,
    cognitoUsersSelector,
    ssusSelector,
    studiesSelector,
    sitesSelector,
    roleTxnsRolledUpSelector,
    previousRolesSelector,
    roleTxnsSelector,
    (state, props) => props.match.params.uuid,
    userSelector
  );
};

export default makeUserSelector;
