import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Formik, Form } from 'formik';
import StandardTable from '../gizmos/StandardTable';
import commonColumns from './CommonColumns';
import { timeZones, marketplaceRoles } from '../../constants';
import ExpireDateForm from './ExpireDateForm';

const ExpireFormSingle = (
  postExpireRoleRequest,
  {
    app,
    roleName,
    ssuId,
    blindingGroup,
    groupAssign,
    financeDetailsAccess,
    expensesAccess,
    patientPaymentAccess,
    remittanceNotification,
    timeZoneObj = {}
  },
  userId,
  today,
  timeZoneDataSource,
  postCognitoUserRemoveGroupRequest
) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        showDateTimeFields: false,
        expireTime: '23:59',
        expireDate: today,
        timeZone: timeZoneObj.id || '',
        timeZoneAbbr: timeZoneObj.abbr || '',
        googleTimeZoneName: timeZoneObj.googleName || ''
      }}
      onSubmit={values => {
        const payload = {
          role: roleName,
          ssu: ssuId,
          expireDate: (values.showDateTimeFields && values.expireDate) || null,
          expireTime: (values.showDateTimeFields && values.expireTime) || null,
          timeZone: (values.showDateTimeFields && values.timeZone) || null,
          timeZoneAbbr:
            (values.showDateTimeFields && values.timeZoneAbbr) || null,
          googleTimeZoneName:
            (values.showDateTimeFields && values.googleTimeZoneName) || null,
          blindingGroup,
          groupAssignId: groupAssign ? groupAssign._id : null,
          financeAccess: {
            financeDetailsAccess,
            expensesAccess,
            patientPaymentAccess,
            remittanceNotification
          }
        };
        if (marketplaceRoles.includes(roleName)) {
          postCognitoUserRemoveGroupRequest(userId, payload);
        } else {
          postExpireRoleRequest(userId, payload);
        }
      }}
    >
      {({ values, errors, touched }) =>
        (app === 'Study Marketplace' && (
          <Form>
            <button type="submit">Expire Now</button>
          </Form>
        )) || (
          <ExpireDateForm
            values={values}
            errors={errors}
            touched={touched}
            showDateTimeText=" Set Expire Date"
            timeZoneObj={timeZoneObj}
            timeZoneDataSource={timeZoneDataSource}
            submitText="Expire Now"
            showDateTimeSubmitText="Expire"
          />
        )
      }
    </Formik>
  );
};

const ExpireFormAll = ({
  postCognitoUserRemoveGroupRequest,
  postExpireAllRolesRequest,
  roles,
  userId
}) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        roles,
        userId
      }}
      onSubmit={values => {
        const mktPlaceRoles = roles.filter(role =>
          marketplaceRoles.includes(role.roleName)
        );
        if (mktPlaceRoles.length > 0) {
          mktPlaceRoles.forEach(role => {
            postCognitoUserRemoveGroupRequest(userId, {
              role: role.roleName
            });
          });
        }
        const payload =
          (values.expireDate && { expireDate: values.expireDate }) || {};
        postExpireAllRolesRequest(values.userId, payload);
      }}
    >
      {() => (
        <Form>
          <button type="submit">Expire All</button>
        </Form>
      )}
    </Formik>
  );
};

ExpireFormAll.propTypes = {
  postExpireAllRolesRequest: PropTypes.func.isRequired,
  postCognitoUserRemoveGroupRequest: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  userId: PropTypes.string.isRequired
};

const UserRolesTable = ({
  postExpireRoleRequest,
  postExpireAllRolesRequest,
  postCognitoUserRemoveGroupRequest,
  roles,
  userId
}) => {
  const today = moment().format('YYYY-MM-DD');
  const timeZoneDataSource = timeZones.map(zone => ({
    ...zone,
    size: 'Medium'
  }));
  // Role | Study | Site | Start Date | Expire Date | Actions
  const columns = [
    {
      Header: 'App',
      accessor: 'app',
      minWidth: 150
    },
    ...commonColumns,
    {
      Header: 'Actions',
      accessor: 'actions',
      minWidth: 400,
      Cell: ({ original }) =>
        ExpireFormSingle(
          postExpireRoleRequest,
          original,
          userId,
          today,
          timeZoneDataSource,
          postCognitoUserRemoveGroupRequest
        )
    }
  ];

  return (
    <Fragment>
      <h3>Roles</h3>
      {roles && roles.length > 0 && (
        <div style={{ textAlign: 'right' }}>
          <ExpireFormAll
            postCognitoUserRemoveGroupRequest={
              postCognitoUserRemoveGroupRequest
            }
            postExpireAllRolesRequest={postExpireAllRolesRequest}
            roles={roles}
            userId={userId}
          />
        </div>
      )}
      <StandardTable
        columns={columns}
        data={roles}
        showPagination={false}
        pageSize={roles.length}
        sorted={[
          {
            id: 'app',
            desc: true
          }
        ]}
      />
    </Fragment>
  );
};

UserRolesTable.defaultProps = {
  roles: [],
  userId: ''
};

UserRolesTable.propTypes = {
  postExpireRoleRequest: PropTypes.func.isRequired,
  postExpireAllRolesRequest: PropTypes.func.isRequired,
  postCognitoUserRemoveGroupRequest: PropTypes.func.isRequired,
  roles: PropTypes.array,
  userId: PropTypes.string
};

export default UserRolesTable;
