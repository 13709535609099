const financeAccessOptionsList = [
  {
    name: 'Finance Detail Access',
    id: 'financeDetailsAccess',
    initialValue: false
  },
  {
    name: 'Remittance Notification',
    id: 'remittanceNotification',
    initialValue: false,
    disabled: true
  }
];

const expensesAccessOption = {
  name: 'Expenses Access',
  id: 'expensesAccess',
  initialValue: false
};

const financeAccessOptions = {
  stuman: [
    {
      name: 'Patient Payment Access',
      id: 'patientPaymentAccess',
      initialValue: true
    },
    expensesAccessOption,
    ...financeAccessOptionsList
  ],
  pi: financeAccessOptionsList,
  opman: [expensesAccessOption, ...financeAccessOptionsList],
  sitefincoord: financeAccessOptionsList
};
export default financeAccessOptions;
